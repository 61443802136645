import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import { HowItWorks } from "../components/HowItWorks";
import { EnterEmail } from "../components/EnterEmail";

export default function DienstPage({ data }) {
  console.log(`blog data`, data);
  return (
    <Layout selected="diensten">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${data.contentfulDienstPagina.title} | Itadise Diensten`}</title>
      </Helmet>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
              <defs>
                <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-4xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-5xl">{data.contentfulDienstPagina.title}</span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">{data.contentfulDienstPagina.subtitle}</p>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <div
              dangerouslySetInnerHTML={{
                __html: data.contentfulDienstPagina.mainText.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
      </div>
      <HowItWorks />
      <EnterEmail />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    contentfulDienstPagina(slug: {eq: $slug}) {
      id
      mainText {
        childMarkdownRemark {
          html
        }
      }
      title
      subtitle
    }
  }
`;